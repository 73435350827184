import { db } from "../firebase";

const actions = {
  async getStats() {
    try {
      const stats = await db.collection("--stats--").doc("stats").get();

      return {
        success: true,
        stats: stats.data(),
      };
    } catch (error) {
      console.log(error);
      return {
        success: false,
        message: error.message,
      };
    }
  },
};

export default {
  namespaced: true,
  actions,
};
