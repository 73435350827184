import firebase from "firebase/app";
import moment from "moment";
import { db, auth } from "../firebase";
import axios from "axios";

const actions = {
  async searchUser({}, payload) {
    try {
      let user;
      if (payload.searchTerm === "id") {
        user = await db
          .collection("users")
          .where("uid", "==", payload.search?.trim())
          .where("disabled", "==", false)
          .limit(1)
          .get();

        if (!user.empty) {
          return {
            success: true,
            user: user.docs[0].data(),
          };
        } else {
          return {
            success: false,
            message: "El usuario no existe o está deshabilitado",
          };
        }
      } else {
        user = await db
          .collection("users")
          .where("email", "==", payload.search?.trim())
          .where("disabled", "==", false)
          .limit(1)
          .get();

        if (!user.empty) {
          return {
            success: true,
            user: user.docs[0].data(),
          };
        } else {
          return {
            success: false,
            message: "El usuario no existe o está deshabilitado",
          };
        }
      }
    } catch (error) {
      console.log(error);
      return {
        success: false,
        message: error.message,
      };
    }
  },

  async deleteUser({}, { uid, companyId }) {
    try {
      let currentUser = auth.currentUser;

      const token = await currentUser.getIdToken();

      const response = await axios.post(
        "https://us-central1-peluditos-club-mars.cloudfunctions.net/deleteUser",
        {
          uid,
          companyId,
        },
        {
          headers: {
            Authorization: "Bearer " + token,
            "Access-Control-Allow-Origin": "*",
          },
        }
      );

      if (response.data.success) {
        return { success: true, message: "Eliminado con éxito" };
      } else {
        return { success: false, message: response.data.message };
      }
    } catch (error) {
      return { success: false, message: error.message };
    }
  },

  async getUserPoints({}, uid) {
    try {
      const year = new Date().getFullYear().toString();
      const points = await db
        .collection("points")
        .where("uid", "==", uid)
        .where("year", "==", year)
        .get();
      let arrayPoints = [];
      points.docs.forEach((point) => {
        arrayPoints.push(point.data());
      });
      return {
        success: true,
        points: arrayPoints,
      };
    } catch (error) {
      console.log(error);
      return {
        success: false,
        message: error.message,
      };
    }
  },

  async getUserChallenges({}, uid) {
    try {
      const challenges = await db
        .collection("challenges")
        .where("uid", "==", uid)
        .where("isValid", "==", false)
        .get();
      let arrayChallenges = [];
      challenges.docs.forEach((challenge) => {
        const obj = {
          id: challenge.id,
          ...challenge.data(),
        };
        arrayChallenges.push(obj);
      });
      return {
        success: true,
        challenges: arrayChallenges,
      };
    } catch (error) {
      console.log(error);
      return {
        success: false,
        message: error.message,
      };
    }
  },

  async getConferences() {
    try {
      const conferences = await db
        .collection("conferences")
        .where("visible", "==", true)
        .get();
      let arrayConferences = [];
      conferences.docs.forEach((conference) => {
        const obj = {
          id: conference.id,
          ...conference.data(),
        };
        arrayConferences.push(obj);
      });
      return {
        success: true,
        conferences: arrayConferences,
      };
    } catch (error) {
      console.log(error);
      return {
        success: false,
        message: error.message,
      };
    }
  },

  async getCurrentPeriod() {
    try {
      const config = await db.collection("config").doc("global").get();
      return {
        success: true,
        currentPeriod: config.data().currentPeriod,
      };
    } catch (error) {
      console.log(error);
      return {
        success: false,
        message: error.message,
      };
    }
  },

  async setPoint({}, obj) {
    try {
      const batch = db.batch();
      batch.set(db.collection("points").doc(), obj);
      batch.update(db.collection("users").doc(obj.uid), {
        points: firebase.firestore.FieldValue.increment(obj.points),
        totalPoints: firebase.firestore.FieldValue.increment(obj.points),
      });
      await batch.commit();
    } catch (error) {
      console.log(error);
      return {
        success: false,
        message: error.message,
      };
    }
  },

  async setChallengePoint({}, { obj, challenge }) {
    try {
      const batch = db.batch();
      batch.set(db.collection("points").doc(), obj);
      batch.update(db.collection("users").doc(obj.uid), {
        points: firebase.firestore.FieldValue.increment(obj.points),
        totalPoints: firebase.firestore.FieldValue.increment(obj.points),
      });
      batch.update(db.collection("challenges").doc(challenge.id), {
        isValid: true,
      });
      await batch.commit();
    } catch (error) {
      console.log(error);
      return {
        success: false,
        message: error.message,
      };
    }
  },

  async setCSChallengeP13Part2({}, { docId, obj }) {
    try {
      const batch = db.batch();

      batch.update(db.collection("cs_challenges").doc(docId), obj);
      if (obj.isReviewed) {
        batch.update(db.collection("--stats--").doc("stats"), {
          challenge_cs_p13: firebase.firestore.FieldValue.increment(1),
        });
      }

      await batch.commit();

      return { success: true };
    } catch (error) {
      console.error(error);
      return { success: false };
    }
  },

  async rejectChallengePoint({}, challengeId) {
    try {
      await db
        .collection("challenges")
        .doc(challengeId)
        .update({ isValid: true });
    } catch (error) {
      console.log(error);
      return {
        success: false,
        message: error.message,
      };
    }
  },

  async getActivityReport({}, { from, to }) {
    const XLSX = require("xlsx");
    let fromLocal = new Date(from).getTime();
    let toLocal = new Date(to).getTime();

    if (from > to) {
      fromLocal = new Date(to).getTime();
      toLocal = new Date(from).getTime();
    }

    const [usersActive, usersActive2, usersActive3, usersActive4] =
      await Promise.all([
        db
          .collection("logs")
          .where("timestamp", ">=", fromLocal)
          .where("timestamp", "<=", toLocal)
          .get(),
        db
          .collection("challenges_2023")
          .where("createdAt", ">=", fromLocal)
          .where("createdAt", "<=", toLocal)
          .get(),
        db
          .collection("challenges_2024")
          .where("createdAt", ">=", fromLocal)
          .where("createdAt", "<=", toLocal)
          .get(),
        db
          .collection("certifications")
          .where("completedAt", ">=", fromLocal)
          .where("completedAt", "<=", toLocal)
          .get(),
      ]);

    const users = (
      await db
        .collection("users")
        .where("disabled", "==", false)
        .where("companyName", "!=", "OKANA")
        .get()
    ).docs.map((doc) => doc.data());

    let companies = [];
    (
      await Promise.all(
        Array.from(new Set(users.map((user) => user.companyId))).map((cid) =>
          db.collection("companies").doc(cid).get()
        )
      )
    ).forEach((doc) => {
      if (doc.exists) companies.push(doc.data());
    });

    const finalArray = users.map((user) => {
      const anyLog = usersActive.docs.filter((log) => {
        return log.data().uid === user.uid;
      });
      const anyLog2 = usersActive2.docs.filter((log) => {
        return log.data().uid === user.uid;
      });
      const anyLog3 = usersActive3.docs.filter((log) => {
        return log.data().uid === user.uid;
      });
      const anyLog4 = usersActive4.docs.filter((log) => {
        return log.data().uid === user.uid;
      });
      const logsCount =
        anyLog.length + anyLog2.length + anyLog3.length + anyLog4.length;
      const company = companies.find(
        (company) => company.cid === user.companyId
      );

      return {
        ESTADO: logsCount > 0 ? "ACTIVO" : "INACTIVO",
        "FECHA DE REGISTRO": new Date(user.createdAt).toLocaleDateString(),
        NOMBRE: user.name,
        EMAIL: user.email,
        CELULAR: user.phone,
        CIUDAD: user.city.toUpperCase(),
        DIRECCIÓN: user.address,
        ROL: user.role === "WORKER" ? "COLABORADOR" : "PROPIETARIO",
        EMPRESA: company?.name ?? "EMPRESA ELIMINADA",
        NIT: company?.nit ?? "EMPRESA ELIMINADA",
        TRACKSALES: company?.cid ?? "EMPRESA ELIMINADA",
        REGION: company?.region ?? "EMPRESA ELIMINADA",
        "DIRECCIÓN EMPRESA": company?.address ?? "EMPRESA ELIMINADA",
        INTERACCIONES: logsCount,
      };
    });

    const workSheet = XLSX.utils.json_to_sheet(finalArray);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "Usuarios");
    XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    XLSX.writeFile(workBook, "USUARIOS ACTIVOS E INACTIVOS.xlsx");
  },

  async getUsers({}, to) {
    const allUsers = await db.collection("users").get();
    const correctUsers = allUsers.docs.filter((user) => {
      return !user.data().disabled && user.data().role === "WORKER";
    });

    const users = [];

    correctUsers.forEach((user) => {
      users.push(user.data());
    });
    return users;
  },

  async getUserDCMChallenges({}, uid) {
    try {
      const challenges = await db
        .collection("dcm_challenges")
        .where("uid", "==", uid)
        .where("isValid", "==", true)
        .get();
      let arrayChallenges = [];
      challenges.docs.forEach((challenge) => {
        const obj = {
          id: challenge.id,
          created: challenge.data().createdAt
            ? moment(challenge.data().createdAt).format("DD/MM/YYYY")
            : moment(challenge.data().cretedAt).format("DD/MM/YYYY"),
          challengeId: challenge.data().challengeId,
        };
        arrayChallenges.push(obj);
      });
      return {
        success: true,
        challenges: arrayChallenges,
      };
    } catch (error) {
      console.log(error);
      return {
        success: false,
        message: error.message,
      };
    }
  },

  async searchCompany({}, companyId) {
    try {
      const company = await db.collection("companies").doc(companyId).get();
      if (company.exists) {
        return company.data();
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  },
};

export default {
  namespaced: true,
  actions,
};
