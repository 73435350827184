import Vue from 'vue'
import Vuex from 'vuex'

import companies from './companies';
import redemptions from './redemptions';
import stats from './stats';
import user from './user';
import challenges from './challenges';
import reports from './reports';
import points from './points';
import purchases from './purchases';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    companies,
    redemptions,
    stats,
    user,
    challenges,
    reports,
    points,
    purchases
  }
})
