import { db } from '../firebase';

const state = {
  redemptions: [],
  christmas_redemptions: []
}

const mutations = {
  SET_REDEMPTIONS: (state, payload) => {
    state.redemptions = payload;
  },
  SET_CHRISTMAS_REDEMPTIONS: (state, payload) => {
    state.christmas_redemptions = payload;
  },
} 

const actions = {
  getRedemptions({ commit }) {
    db.collection("redemptions")
      .orderBy("redemptionDate", "asc")
      .onSnapshot((redemptions) => {
        var r = [];
        redemptions.forEach((doc) => r.push({ ...doc.data(), id: doc.id }));
        commit("SET_REDEMPTIONS", r);
      });
  },

  getRedemptionsDCM({ commit }) {
    db.collection("dcm_redemptions")
      .orderBy("redemptionDate", "asc")
      .onSnapshot((redemptions) => {
        var r = [];
        redemptions.forEach((doc) => r.push({ ...doc.data(), id: doc.id }));
        commit("SET_REDEMPTIONS", r);
      });
  },

  getRedemptionsCS({ commit }) {
    db.collection("cs_redemptions")
      .orderBy("redemptionDate", "asc")
      .onSnapshot((redemptions) => {
        var r = [];
        redemptions.forEach((doc) => r.push({ ...doc.data(), id: doc.id }));
        commit("SET_REDEMPTIONS", r);
      });
  },

  getChristmasRedemptions({ commit }) {
    db.collection("christmas_redemptions")
      .orderBy("redemptionDate", "asc")
      .onSnapshot((redemptions) => {
        var r = [];
        redemptions.forEach((doc) => r.push({ ...doc.data(), id: doc.id }));
        commit("SET_CHRISTMAS_REDEMPTIONS", r);
      });
  },

  getRedemptionsPoints({ commit }) {
    db.collection("challenges_2023_redemptions")
      .orderBy("redemptionDate", "asc")
      .onSnapshot((redemptions) => {
        var r = [];
        redemptions.forEach((doc) => r.push({ ...doc.data(), id: doc.id }));
        commit("SET_REDEMPTIONS", r);
      });
  },


  async updateRedemptionStatus({}, redemption) {
    await db
      .collection("redemptions")
      .doc(redemption.id)
      .update({ status: redemption.newStatus, updatedAt: Date.now() });
  },

  async updateRedemptionStatusDCM({}, redemption) {
    await db
      .collection("dcm_redemptions")
      .doc(redemption.id)
      .update({ status: redemption.newStatus, updatedAt: Date.now() });
  },

  async updateRedemptionStatusCS({}, redemption) {
    await db
      .collection("cs_redemptions")
      .doc(redemption.id)
      .update({ status: redemption.newStatus, updatedAt: Date.now() });
  },

  async updateChristmasRedemptionStatus({}, redemption) {
    await db
      .collection("christmas_redemptions")
      .doc(redemption.id)
      .update({ status: redemption.newStatus, updatedAt: Date.now() });
  },

  async updateRedemptionPointsStatus({}, redemption) {
    await db
      .collection("challenges_2023_redemptions")
      .doc(redemption.id)
      .update({ status: redemption.newStatus, updatedAt: Date.now() });
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};