<template>
  <div class="home relative flex flex-col min-h-screen py-8">
    <img class="self-center my-auto w-1/4" src="@/assets/images/logo.png" alt="Peluditos Club Mars">
  </div>
</template>

<script>
import { db } from '../firebase';
export default {
  name: "Home",
  data() {
    return {
      showModal: false,
      name: '',
      lastname: '',
      messages: [],
      message: ''
    }
  },

  mounted() {
    db.collection("chat").orderBy("timestamp", "asc").onSnapshot(snap => {
      snap.docChanges().forEach((change) => {
        if (change.type === "added") {
          this.messages.push(change.doc.data());
          setTimeout(this.scrollToBottom, 500);
        }
      });
    })
    if(localStorage.getItem('name') === null && localStorage.getItem('lastname') === null) {
      this.showModal = true;
    } else {
      this.name = localStorage.getItem('name');
      this.lastname = localStorage.getItem('lastname');
    }
  },

  methods: {
    setUser() {
      localStorage.setItem('name', this.name);
      localStorage.setItem('lastname', this.lastname);
      this.showModal = false;
    },
    async setMessage() {
      try {
        await db.collection("chat").doc().set({
          name: this.name,
          lastname: this.lastname,
          message: this.message,
          timestamp: Date.now(),
        });
        this.message = "";
        this.scrollToBottom();
      } catch (error) {
        console.log(error);
      }
    },
    scrollToBottom() {
      if (this.$refs.chats)
        this.$refs.chats.scrollTop = this.$refs.chats.scrollHeight;
    },
  }
};
</script>

<style lang="scss">
  .home {
    background: url("../assets/images/bg-stream.jpg") no-repeat;
    background-size: cover;
    background-repeat: no-repeat;
    font-family: "Nunito";

    .iframe-container {
      width: 66%;
      background-color: #014F94;
      height: 420px;
      padding: 0.5rem;
      border-radius: 10px;
      z-index: 2;

      @media only screen and (max-width: 700px) {
          width: 100%;
          height: 250px;
      }

      iframe {
        border-radius: 10px;
      }
    }

    .chat-container {
      border: 2px solid #014F94;
      border-radius: 10px;
      width: 33%;
      height: 420px;
      margin-left: -1rem;
      padding: 0.5rem 2rem;

      @media only screen and (max-width: 700px) {
        width: 100%;
        height: 350px;
        margin-left: 0;
        margin-top: -1rem;
        padding: 1rem 2rem;
      }

      h2 {
        font-family: "Mars";
        color: #014F94;
        text-transform: uppercase;
        font-size: 1.5rem;
      }
    }

    .img-logos {
      border: 2px solid #014F94;
    }
  }

  .modal-container {
    font-family: "Nunito" !important;
    background-color: #FAB906;

    .info-container {
      background: url('../assets/images/bg-modal.png') no-repeat;
      background-size: cover;
      background-color: white;
      border-radius: 2.5rem;

      input {
        border: 1px solid #606060;
      }
    }
  }
</style>
