import { db } from "../firebase";
import firebase from "firebase/app";

const actions = {
  async setPointsManual({}, data) {
    try {
      const config = (await db.collection("config").doc("global").get()).data();
      const batch = db.batch();
      batch.set(db.collection("points").doc(), {
        ...data,
        year: config.year,
      });
      batch.update(db.collection("--stats--").doc("stats"), {
        qtyManualPoints: firebase.firestore.FieldValue.increment(1), //Contador de registros Manuales
      });
      batch.update(db.collection("users").doc(data.uid), {
        points: firebase.firestore.FieldValue.increment(data.points), //Contador de registros Manuales
        totalPoints: firebase.firestore.FieldValue.increment(data.points), //Contador de registros Manuales
      });

      await batch.commit();
    } catch (error) {
      console.log(error);
      return {
        success: false,
        message: error.message,
      };
    }
  },
};

export default {
  namespaced: true,
  actions,
};
