import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

import { auth } from "../firebase";

Vue.use(VueRouter);

const routes = [
	{
		path: "/",
		name: "Home",
		component: Home,
	},
	{
		path: "/about",
		name: "About",
		component: () => import("../views/About.vue"),
	},
	{
		path: "/terminos-y-condiciones",
		name: "TYC",
		component: () => import("../views/TYC.vue"),
	},
	{
		path: "/empresa/:uid",
		name: "Company",
		component: () => import("../views/Company.vue"),
	},
	{
		path: "/login",
		name: "Login",
		component: () => import("../views/Login.vue"),
	},
	{
		path: "/user/:uid",
		name: "User",
		component: () => import("../views/User.vue"),
	},
	{
		path: "/view-invoices/:id",
		name: "invoices",
		component: () => import("../views/ViewInvoices.vue"),
	},
	{
		path: "/dashboard",
		name: "Dashboard",
		component: () => import("../views/Dashboard/Home.vue"),
		children: [
			{
				path: "/dashboard",
				component: () => import("../views/Dashboard/Companies.vue"),
			},
			{
				path: "/dashboard/redenciones",
				component: () => import("../views/Dashboard/Redemptions.vue"),
			},
			{
				path: "/dashboard/estadisticas",
				component: () => import("../views/Dashboard/Stats.vue"),
			},
			{
				path: "/dashboard/buscar-usuario",
				component: () => import("../views/Dashboard/SearchUser.vue"),
			},
			{
				path: "/dashboard/retos",
				component: () => import("../views/Dashboard/Challenges.vue"),
			},
			{
				path: "/dashboard/usuarios",
				component: () => import("../views/Dashboard/Users.vue"),
			},
			{
				path: "/dashboard/retos-dcm",
				component: () => import("../views/Dashboard/DCMChallenges.vue"),
			},
			{
				path: "/dashboard/reportes",
				component: () => import("../views/Dashboard/Reports.vue"),
			},
			{
				path: "/dashboard/retos-dcm-p03",
				component: () => import("../views/Dashboard/ChallengesDCMP03.vue"),
			},
			{
				path: "/dashboard/retos-dcm-p05",
				component: () => import("../views/Dashboard/ChallengesDCMP05.vue"),
			},
			{
				path: "/dashboard/listar-compras",
				component: () => import("../views/Dashboard/Purchases.vue"),
			},
			{
				path: "/dashboard/cargar-compras",
				component: () => import("../views/Dashboard/LoadPurchases.vue"),
			},
			{
				path: "/dashboard/redenciones-dcm",
				component: () => import("../views/Dashboard/RedemptionsDCM.vue"),
			},
			{
				path: "/dashboard/redenciones-cs",
				component: () => import("../views/Dashboard/RedemptionsCS.vue"),
			},
			{
				path: "/dashboard/redenciones-puntos",
				component: () => import("../views/Dashboard/RedemptionPoints.vue"),
			},
			{
				path: "/dashboard/premios",
				component: () => import("../views/Dashboard/Awards.vue"),
			},
			{
				path: "/dashboard/usuarios/crear",
				component: () => import("../views/Dashboard/CreateUser.vue"),
			},
			{
				path: "/dashboard/validate-academy",
				component: () => import("../views/Dashboard/ValidateAcademy.vue"),
			},
		],
		meta: {
			requiresAuth: true,
		},
	},
];

const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	routes,
});

router.beforeEach(async (to, from, next) => {
	let currentUser = auth.currentUser;
	var isAdmin;
	if (currentUser != null) {
		const token = await currentUser.getIdTokenResult();
		isAdmin = token.claims.admin;
	}
	const requiresAuth = to.matched.some((x) => x.meta.requiresAuth);
	if (requiresAuth && !currentUser) {
		next("/login");
		// } else if (currentUser && !isAdmin) {
		//   next("/login");
	} else {
		next();
	}
});

export default router;
