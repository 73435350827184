import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/analytics";
import "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyCXW_uTenkZtLUR_SHJLXL0kekcL1GIyio",
  authDomain: "peluditos-club-mars.firebaseapp.com",
  projectId: "peluditos-club-mars",
  storageBucket: "peluditos-club-mars.appspot.com",
  messagingSenderId: "588614935153",
  appId: "1:588614935153:web:3828d45349eb0ab4afb1d0",
  measurementId: "G-QRW9GY0N52"
};
// Initialize Firebase
var firebaseApp = firebase.initializeApp(firebaseConfig);

const db = firebaseApp.firestore();
const auth = firebaseApp.auth();
firebaseApp.analytics();

export { db, auth };