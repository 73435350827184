import { db } from "../firebase";

const state = {
  actualCompany: null,
};

const mutations = {
  SET_ACTUAL_COMPANY: (state, payload) => {
    state.actualCompany = payload;
  },
};

const actions = {
  async getCompanies() {
    try {
      const data = await db.collection("companies").get();
      let array = [];
      data.docs.forEach((doc) => {
        array.push({
          id: doc.id,
          ...doc.data(),
        });
      });
      return {
        success: true,
        data: array,
      };
    } catch (error) {
      console.log(error);
      return {
        success: false,
        message: error.message,
      };
    }
  },

  async getCompanyById({ commit }, id) {
    try {
      const company = await db.collection("companies").doc(id).get();
      const goals = await db
        .collection("companies")
        .doc(id)
        .collection("newGoals")
        .doc("2023")
        .get();
      commit("SET_ACTUAL_COMPANY", company.data());
      return {
        success: true,
        data: company.data(),
        goals: goals.data(),
      };
    } catch (error) {
      return {
        success: false,
        message: error.message,
      };
    }
  },

  async getCompanyPurchases({ state }) {
    try {
      const config = await db.collection("config").doc("global").get();
      const purchases = await db
        .collection("purchases")
        .where("companyId", "==", state.actualCompany.cid)
        .where("year", "==", config.data().year)
        .get();
      let array = [];
      purchases.docs.forEach((purchase) => {
        array.push({
          id: purchase.id,
          ...purchase.data(),
        });
      });

      let arrayActualPurchases = [];

      const currentPeriod = config.data().currentPeriod;
      const actualPurchases = purchases.docs.filter(
        (purchase) => purchase.data().period === currentPeriod
      );
      actualPurchases.forEach((p) => {
        arrayActualPurchases.push(p.data());
      });

      return {
        success: true,
        purchases: array,
        actualPurchases: arrayActualPurchases,
        currentPeriod,
      };
    } catch (error) {
      console.log(error);
      return {
        success: false,
        message: error.message,
      };
    }
  },

  async setPurchase({}, { amount, companyId }) {
    try {
      await db.collection("purchases").doc().set({
        amount,
        companyId,
        period: "p13",
        timestamp: Date.now(),
        year: "2022",
      });

      return { success: true };
    } catch (error) {
      console.log(error);
      alert("Error al cargar compra");
      return { success: false };
    }
  },

  async getCompanyUsers({ state }) {
    try {
      const users = await db
        .collection("users")
        .where("companyId", "==", state.actualCompany.cid)
        .get();
      const workers = users.docs.filter((user) => !user.data().disabled);
      let workersLength = 0;
      let ownersLength = 0;
      workers.forEach((worker) => {
        if (worker.data().role === "WORKER") {
          workersLength++;
        } else if (worker.data().role === "OWNER") {
          ownersLength++;
        }
      });

      return {
        success: true,
        users: workers.length,
        workersLength,
        ownersLength,
      };
    } catch (error) {
      console.log(error);
      return {
        success: false,
        message: error.message,
      };
    }
  },

  async getCompanyCompliance({}, cid) {
    try {
      const compliance = await db
        .collection("compliance")
        .where("companyId", "==", cid)
        .where("year", "==", "2022")
        .orderBy("createdAt", "asc")
        .get();
      let arrayCompliance = [];
      compliance.docs.forEach((c) => {
        arrayCompliance.push(c.data());
      });
      return {
        success: true,
        compliance: arrayCompliance,
      };
    } catch (error) {
      console.log(error);
      return {
        success: false,
        message: error.message,
      };
    }
  },

  async updateMaxWorkers({}, { companyData, newValue }) {
    if (companyData.workers > newValue) {
      return {
        success: false,
        message:
          "El nuevo valor debe ser mayor o igual al número de trabajadores registrados",
      };
    }

    try {
      await db.collection("companies").doc(companyData.cid).update({
        maxWorkers: newValue,
      });
      return {
        success: true,
        message: "",
      };
    } catch (error) {
      return {
        success: false,
        message: "No fue posible actualizar número de cupos",
      };
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
