import { db, auth } from "../firebase";
import firebase from "firebase/app";
import { read, utils, writeFile, write } from "xlsx";

let dataCompanies = {};
let arrayFiles = [];

let dataErrorFile = [];

const getCompanies = async () => {
	const companies = await db
		.collection("companies")
		.where("name", "!=", "OKANA")
		.get();

	companies.docs.forEach((company) => {
		dataCompanies[company.data().nit] = {
			nit: company.data().nit,
			tracksales: company.id,
		};
	});
};

const getDataConfig = async () => {
	const config = await db.collection("config").doc("global").get();

	return {
		year: config.data().year,
	};
};

const saveTransaction = async (config, period, bimester, timeStamp) => {
	const docData = {
		year: config.year,
		period: period,
		length: arrayFiles.length,
		timeStamp,
		bimester: bimester.toString(),
		state: "CARGADO",
		canBeReversed: true,
		createdBy: auth.currentUser.email,
		updatedBy: "",
		timeStampUpdated: 0,
	};

	const docRef = db.collection("purchase_transactions").doc();
	await docRef.set(docData);

	return docRef.id;
};

const validateData = (file) => {
	dataErrorFile = [];
	arrayFiles = [];

	const workbook = read(file, { type: "array" });
	const worksheet = workbook.Sheets[workbook.SheetNames[0]];

	const sheet = utils.sheet_to_json(worksheet, {
		header: ["A", "B", "C", "D"],
	});
	const numberOfHeaders = Object.keys(sheet[0]);

	if (
		sheet[0].A !== "NIT" ||
		sheet[0].B !== "SECO" ||
		sheet[0].C !== "HUMEDO" ||
		sheet[0].D !== "SNACK"
	)
		return {
			error: true,
			message:
				'El archivo tiene un formato distinto al esperado:\nDebe enviar el siguiente: "NIT", "SECO", "HUMEDO" y "SNACK"',
		};

	for (const row of sheet) {
		if (row.__rowNum__ <= 0) continue;

		const nit = row?.A?.toString().trim();
		const amountDry = row?.B;
		const amountWet = row?.C;
		const amountSnack = row?.D;
		const tracksales = dataCompanies[nit]?.tracksales;

		if (Object.keys(row).length !== numberOfHeaders.length) {
			dataErrorFile.push({
				nit,
				amountDry,
				amountWet,
				amountSnack,
				message: "Faltan valores en esta fila.",
			});
			continue;
		}

		if (!tracksales) {
			dataErrorFile.push({
				nit,
				amountDry,
				amountWet,
				amountSnack,
				message: `El nit no se encuentra registrado en nuestra base de datos.`,
			});
			continue;
		}

		if (arrayFiles.find((file) => file.tracksales === nit)) {
			dataErrorFile.push({
				nit,
				amountDry,
				amountWet,
				amountSnack,
				message: `El nit se encuentra repetido.`,
			});
			continue;
		}

		if (isNaN(amountDry) || isNaN(amountSnack) || isNaN(amountWet)) {
			let product = "";
			product = isNaN(amountDry) ? "SECO" : "";
			product += isNaN(amountWet) ? "HUMEDO" : "";
			product += isNaN(amountSnack) ? "SNACK" : "";

			dataErrorFile.push({
				nit,
				amountDry,
				amountWet,
				amountSnack,
				message: `La cantidad de "${product}" no es un número, por favor revisar y corregir.`,
			});
			continue;
		}

		if (amountDry < 0 || amountSnack < 0 || amountWet < 0) {
			let product = "";
			product = amountDry < 0 ? "SECO, " : "";
			product += amountWet < 0 ? "HUMEDO, " : "";
			product += amountSnack < 0 ? "SNACK, " : "";

			dataErrorFile.push({
				nit,
				amountDry,
				amountWet,
				amountSnack,
				message: `La cantidad de "${product
					.trim()
					.slice(0, -1)}" es menor a 0, por favor revisar y corregir.`,
			});
			continue;
		}

		const amount = amountDry + amountWet + amountSnack;

		dataErrorFile.push({
			nit,
			amountDry,
			amountWet,
			amountSnack,
			message: "",
		});
		arrayFiles.push({ tracksales, amountDry, amountWet, amountSnack, amount });
	}

	const existError = dataErrorFile.find((error) => error.message != "");

	return {
		error: existError,
		message: existError
			? "El archivo que intentas cargar, contiene algunos errores.\nPor favor, validalos e intenta nuevamente"
			: "",
	};
};

const actions = {
	async loadFile({}, file) {
		await getCompanies();

		const validations = validateData(file);

		if (validations.error) {
			return validations;
		}

		return { error: false };
	},
	async savePurchases({}, { period, bimester }) {
		const config = await getDataConfig();
		const timeStamp = Date.now();
		const transactionId = await saveTransaction(
			config,
			period,
			bimester,
			timeStamp
		);
		const arrayAdd = [];

		for (const file of arrayFiles) {
			const docData = {
				amount: file.amount,
				amountDry: file.amountDry,
				amountSnack: file.amountSnack,
				amountWet: file.amountWet,
				companyId: file.tracksales,
				period,
				bimester: bimester.toString(),
				year: config.year,
				timeStamp: timeStamp,
				transactionId,
			};

			arrayAdd.push(db.collection("purchases").doc().set(docData));
		}

		await Promise.all(arrayAdd);
	},
	async getPurchaseTransactions() {
		const data = await db
			.collection("purchase_transactions")
			.orderBy("timeStamp", "desc")
			.get();

		return data.docs.map((transaction) => ({
			...transaction.data(),
			transactionId: transaction.id,
		}));
	},
	async deleteTansactionWithId({}, transactionId) {
		await db.collection("purchase_transactions").doc(transactionId).update({
			state: "REVERTIDO",
			timeStampUpdated: new Date().getTime(),
			updatedBy: auth.currentUser.email,
			canBeReversed: false,
		});

		const data = await db
			.collection("purchases")
			.where("transactionId", "==", transactionId)
			.get();

		const arrayDelete = [];

		for (const purchase of data.docs) {
			arrayDelete.push(db.collection("purchases").doc(purchase.id).delete());
		}

		await Promise.all(arrayDelete);
	},
	downloadFileWithError() {
		const array = dataErrorFile.map((error) => {
			return {
				NIT: error.nit,
				SECO: error.amountDry,
				HUMEDO: error.amountWet,
				SNACK: error.amountSnack,
				MENSAJE: error.message,
			};
		});

		const workSheet = utils.json_to_sheet(array);
		const workBook = utils.book_new();
		utils.book_append_sheet(workBook, workSheet, "Errores");
		write(workBook, { bookType: "xlsx", type: "buffer" });
		write(workBook, { bookType: "xlsx", type: "binary" });
		writeFile(workBook, "Carga de compras con errores.xlsx");
	},
};

export default {
	namespaced: true,
	actions,
};
